export const MERCHANTS = [
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-1/logos/merchant-1.png'),
    destinationURL:
      'https://www.airtrip.jp/?source=airtrip.jp&utm_source=paidy&utm_medium=email&utm_campaign=paidy_lp_202207',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-1/logos/merchant-2.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-1/logos/merchant-3.png'),
    destinationURL: 'https://travelist.jp/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-1/logos/merchant-4.png'),
    destinationURL: 'https://www.sorahapi.jp?med_id=paidy',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travels-summer-vol-1/logos/merchant-5.png'),
    destinationURL: 'https://domhotel.airtrip.jp',
    category: [2],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: '航空券' },
  { value: 2, label: 'ホテル' },
];
